@import '../../../styles/index';

.base {
  background: $violet-9 $gradient-enercity;
  background-clip: text;
  color: transparent;
  font-size: $font-size-18;
  font-weight: $font-weight-medium;
  white-space: nowrap;

  &.is-inverted {
    background-image: none;
    color: currentColor;
  }

  a {
    color: inherit;
  }
}
